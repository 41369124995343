#form-file-upload {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
}

#input-file-upload {
  display: none;
}

#input-file-upload:disabled + label {
  cursor: not-allowed;
  pointer-events: all !important;
  background-color: #ffffff;
}

#label-file-upload {
  width: 900px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 0.5rem;
  border-style: dashed;
  border-color: #a1a1a1;
  background-color: #ffffff;
}

#label-file-upload:hover {
  background-color: #f6f7f9;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#content {
  padding: 24px;
  color: #a1a1a1;
}

.upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-area {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 0.5rem;
  border: none;
  font-family: "Oswald", sans-serif;
}

.upload-button {
  width: min-content;
  min-height: 50px !important;
  padding: 12px 40px !important;
  border-radius: 0.5em;
  font-size: 20px !important;
}

.upload-button svg {
  width: 20px;
  height: 20px;
}
