.PdfPanel {
  margin-right: 24px;
}

.PagingButtons {
  margin-top: 24px;
  width: 100%;
  padding: 3px;
  display: inline-flex;
  align-items: center;
}

.PagingButtons__middle {
  margin-right: 24px;
  margin-left: 24px;
  background: none;
  cursor: auto;
  text-align: center;
}

.PagingButtons__input {
  display: inline-block;
  width: 40px;
}

.PagingButtons__input input {
  padding: 5px !important;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.PagingButtons__input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.PagingButtons__input input[type="number"] {
  -moz-appearance: textfield;
}
